<template>
  <div class="create-store-class">
    <div v-if="copyFlag" class="title-border">复制FunPinPin店铺</div>
    <div v-else class="title-border">创建FunPinPin店铺</div>
    <!--form 表单-->
    <div class="h120">
      <div class="form-title">*店铺名称</div>
      <PaInput
              class="create-store-input"
              v-model="from.storeName"
              type="ShopName"
              placeholder="请输入店铺名称"
              is_required_text="请输入店铺名称"
              error_content="店铺名称仅支持英文、数字、横线、下划线，且不能以横线或下划线开头"
              :submitType="submitType"
              :is_required="true"
      />
      <div class="form-title">*接收信息邮箱</div>
      <PaInput
              class="create-store-input"
              v-model="from.email"
              type="(^\w+((-w+)|(\.\w+))*\@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z0-9]+$)"
              placeholder="请输入登录邮箱"
              error_content="请输入正确的邮箱"
              is_required_text="请输入登录邮箱"
              :submitType="submitType"
              :is_required="true"
      />
    </div>
    <!--取消&确定按钮-->
    <div class="btn">
      <el-button class="cancel" @click="handleCancel()">取消</el-button>
      <el-button class="sure" @click="handleSure()" :loading="showLoading">确定</el-button>
    </div>
  </div>
</template>

<script>
  import PaInput from "@/components/basic/PaInput.vue";
  import {storeManagement} from "@/assets/js/api";
  import {handleResponseErrors} from "@/assets/js/utils";

  export default {
    name: "createStore",
    props: [
      "copyFlag",
        "rowData"
    ],
    components: {PaInput},
    data() {
      return {
        from: {
          storeName: {value: "", isError: false},
          email: {value: "", isError: false},
        },
        submitType: false,
        createSuccessDialog: false,
        storeName: '',
        showLoading: false,
      };
    },
    methods: {
      handleCancel() {
        if (!this.showLoading) {
          this.$parent.$parent.createStoreDialog = false
        }
      },
      handleSure() {
        this.submitType = true;
        this.$nextTick(async () => {
          if (
              Object.values(this.from).every(item => {
                return item.isError !== true;
              })
          ) {
            this.createStore()
          }
        });
        /* if ((/^(?!-|_)[a-zA-Z0-9_-]+$/.test(this.from.storeName.value)) && !(/funpinpin/.test(String(this.from.storeName.value).toLowerCase())) && (this.from.storeName.value.length >= 4) && (this.from.storeName.value.length < 60)) {
             this.createStore()
         }*/
      },
      async createStore() {
        this.showLoading = true;
        let data={}

        try {
          let res = null;
          if(this.copyFlag){
            data = {
              from_store_name: this.rowData.store_name,
              from_login_email: this.rowData.login_email,
              to_store_name: this.from.storeName.value,
              to_login_email: this.from.email.value,
            }
             res = await storeManagement.copyCreateStore(data);
          }else{
            data = {
              store_name: this.from.storeName.value,
              login_email: this.from.email.value
            };
             res = await storeManagement.getCreateStore(data);
          }
          console.log('复制&创建店铺',res)
          if (res.code !== 0) {
            this.showLoading = false
            handleResponseErrors(res.code, res.msg);
            return;
          }
          else {
            this.storeName = this.from.storeName.value
            const storeInfoObj = {
              storeName: this.from.storeName.value,
              email: res.sent_email,
              login_email: res.login_email
            }
            this.$emit('storeInfoObj', storeInfoObj)
            this.showLoading = false
            this.$parent.$parent.createStoreDialog = false
            this.$parent.$parent.createSuccessDialog = true
          }
        } catch (error) {
          this.showLoading = false;
          console.log(error);
          handleResponseErrors(9000, "服务异常，请稍后重试");
        }
      },
    },
  }
</script>
<style>
  .create-store-input .input-box input {
    font-size: 16px !important;
    min-height: 40px !important;
    /*border: 1px solid #D8D8D8 !important;*/
  }
</style>
<style scoped lang="scss">
  .create-store-class {
    .h120 {
      height: 120px;
    }
    .title-border {
      font-size: 18px;
      padding-bottom: 19px;
      margin-bottom: 19px;
      border-bottom: 1px solid #E2D6CB;
    }
    .form-title {
      margin-top: 20px;
      margin-bottom: 10px;
      font-size: 16px;
      font-weight: 400;
    }
    .btn {
      margin-top: 95px;
      text-align: right;
      button {
        font-size: 16px;
        margin-right: 10px;
        display: inline-block;
        width: 96px;
        height: 40px;
        text-align: center;
        border-radius: 8px;
        cursor: pointer;
        color: #fff;
      }
      .cancel {
        background: #B7BEBC;
      }
      .sure {
        background: #54AB8E;
      }
    }
  }
</style>